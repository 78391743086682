// Photos from https://citizenofnowhe.re
import { useState } from "react";
import { motion } from "framer-motion";
import { VideoActivity } from "./activities/Video";
import { Activity } from "./activities/types";
import { PromptActivity } from "./activities/Prompt";
import { FinalActivity } from "./activities/Final";
import { PromptSeedActivity } from "./activities/PromptSeed";
import { WordCloudActivity } from "./activities/WordCloud";

const activitiesList: Activity[] = [
  {
    id: 0,
    type: "video",
    title: "Welcome to Tara",
    author: "Dr. Katrina Roundfield",
    url: "https://jfxonkvejkqjkgnenewp.supabase.co/storage/v1/object/public/codename-tara-videos/expert.1.MP4",
  },
  {
    id: 1,
    type: "video",
    title: "Encouragement from moms who have been there",
    author: "",
    url: "https://jfxonkvejkqjkgnenewp.supabase.co/storage/v1/object/public/codename-tara-videos/mashup.mov",
  },
  {
    id: 2,
    type: "word-cloud",
    title: "",
    author: "",
    url: "",
  },
  {
    id: 3,
    type: "prompt-seed",
    title: "Did your baby's birth meet your expectations?",
    author: "",
    url: "",
  },
  {
    id: 4,
    type: "video",
    title: "Did your baby's birth meet your expectations?",
    author: "Michelle R.",
    url: "https://jfxonkvejkqjkgnenewp.supabase.co/storage/v1/object/public/codename-tara-videos/relatable.1.MOV",
  },
  {
    id: 5,
    type: "prompt",
    title: "Did your baby's birth meet your expectations?",
    url: "",
    author: "",
  },
  {
    id: 6,
    type: "final",
    title: "Would you like to share with your community?",
    url: "",
    author: "",
  },
];

function ActivityItem({
  activity,
  activeId,
  goNext,
  goPrev,
}: {
  activity: Activity;
  activeId: number;
  goNext: () => void;
  goPrev: () => void;
}) {
  return (
    <motion.div
      className={`h-full flex flex-col justify-center max-w-xl w-full absolute  p-4 transition-all duration-300 bg-tara-purple text-white ${
        activeId < activity.id && "-bottom-full"
      } ${activeId > activity.id && "bottom-[200dvh]"} ${
        activeId === activity.id && "bottom-0"
      }`}
      translate="yes"
      drag="y"
      dragConstraints={{ top: 0, bottom: 0 }}
      onDragEnd={(event, info) => {
        if (info.offset.y < -100) {
          console.log("go next");
          goNext();
        } else if (info.offset.y > 100) {
          console.log("go prev");
          goPrev();
        }
      }}
    >
      {activity.type === "final" && (
        <FinalActivity
          activity={activity}
          isActive={activity.id === activeId}
        />
      )}
      {activity.type === "prompt" && (
        <PromptActivity
          activity={activity}
          isActive={activity.id === activeId}
        />
      )}
      {activity.type === "prompt-seed" && (
        <PromptSeedActivity
          activity={activity}
          isActive={activity.id === activeId}
        />
      )}
      {activity.type === "video" && (
        <VideoActivity
          activity={activity}
          isActive={activity.id === activeId}
        />
      )}
      {activity.type === "word-cloud" && (
        <WordCloudActivity
          activity={activity}
          isActive={activity.id === activeId}
        />
      )}
    </motion.div>
  );
}

export default function DragList() {
  const [activeId, setActiveId] = useState(0);

  return (
    <div className="h-screen flex flex-col bg-tara-purple">
      <div className="min-h-[7dvh] flex justify-between p-4 bg-white z-50 shadow-2xl">
        <p className="underline">For You</p>
        <p>All Content</p>
      </div>
      <div className="h-[93dvh] relative z-40">
        {activitiesList.map((activity) => (
          <ActivityItem
            activity={activity}
            activeId={activeId}
            key={activity.id}
            goNext={() => {
              if (activeId < activitiesList.length - 1) {
                setActiveId(activeId + 1);
              }
            }}
            goPrev={() => {
              if (activeId > 0) {
                setActiveId(activeId - 1);
              }
            }}
          />
        ))}
      </div>
      {/* This div goes behind the browser on mobile */}
      <div className="flex-grow"></div>
    </div>
  );
}
